module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#3283A0","display":"standalone","icon":"src/favicon.png","include_favicon":true,"name":"Drover","short_name":"drover","start_url":"/","theme_color":"#FCA623","cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"c07bbd7ad6fb21c62626e9a9f9ecff5a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:300,700","Maven+Pro:400,700"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
