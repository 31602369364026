import { TopicSectionNcssTree } from '@wheelroom/wheel-topic'
import { DeepPartial } from '@wheelroom/core'
import {
  primaryButtonNcssNode,
  displayButtonNcssNode,
  secondaryButtonNcssNode,
} from '../../../element-ncss/button-ncss'
import { iconButtonNcssNode, iconTopicNcssNode } from '../../icon-ncss'
import { embedNcssNode } from '../../embed-ncss'
import { containerNcssNode } from '../../grid-ncss'

export const topicSectionHeroNcssTree: DeepPartial<TopicSectionNcssTree> = {
  container: containerNcssNode,
  topic: {
    ncssSwitch: {
      container: {
        position: 'relative',
        w: 1,
        pt: 9,
      },
    },
    content: {
      ncss: {
        maxWidth: '1280px',
        mx: 'auto',
        p: [3, 5, 7],
        w: 1,
        mt: [8, 9, 10, 11],
        mb: [5, 6, 7, 8],
      },
    },
    actionGroup: {
      ncss: {
        maxWidth: '30em',
        mt: 5,
      },
    },
    action: {
      ncss: {
        ...primaryButtonNcssNode.ncss,
        ...displayButtonNcssNode.ncss,
        ':nth-of-type(2)': {
          ...secondaryButtonNcssNode.ncss,
          ...displayButtonNcssNode.ncss,
        },
      },
      icon: iconButtonNcssNode,
    },
    text: {
      ncss: {
        bg: 'boxBg',
        borderRadius: '16px',
        color: 'white',
        maxWidth: '40em',
        opacity: 0.9,
        p: 6,
      },
    },
    abstract: {
      ncss: {
        fontSize: [7, 7, 8, 8],
        mb: 0,
      },
    },
    icon: iconTopicNcssNode,
    media: {
      ncss: {
        bottom: '0',
        left: '0',
        position: 'absolute',
        right: '0',
        top: '0',
        overflow: 'hidden',
        zIndex: -1,
      },
    },
    embed: embedNcssNode,
    image: {
      ncss: {
        h: 1,
        objectFit: 'cover',
      },
      picture: {
        ncss: {
          h: 1,
          w: 1,
        },
      },
    },
    video: {
      ncss: {
        h: 1,
      },
    },
  },
  wrapper: {},
}
