import { SectionWheelTheme } from '@wheelroom/core'
import { wrSystemTheme } from '../wr-system-theme'
import { elementNcss } from './element-ncss/element-ncss'
import { styles } from './ncss-trees/styles'
import { blue } from './colors/blue'
import { orange } from './colors/orange'
import { grey } from './colors/grey'
import { data } from './data/data'
import { fontMap } from './fontmap'

export const droverBlue: SectionWheelTheme = {
  themeName: 'Drover blue',
  elementNcss,
  styles,
  data,
  wrSystemTheme: { ...wrSystemTheme, colorMap: blue, fontMap },
}

export const droverOrange: SectionWheelTheme = {
  themeName: 'Drover orange',
  elementNcss,
  styles,
  data,
  wrSystemTheme: { ...wrSystemTheme, colorMap: orange, fontMap },
}

export const droverGrey: SectionWheelTheme = {
  themeName: 'Drover grey',
  elementNcss,
  styles,
  data,
  wrSystemTheme: { ...wrSystemTheme, colorMap: grey, fontMap },
}
