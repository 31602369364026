import { DeepPartial } from '@wheelroom/core'
import { NavigationSectionData } from '@wheelroom/wheel-navigation'
import React from 'react'

export const navigationSectionData: DeepPartial<NavigationSectionData> = {
  header: {
    hideActions: false,
    hideBranding: false,
    hideMenu: false,
    hideModal: false,
    hideSocial: false,
    hideSkipToContent: false,
    hideThemeButton: true,
    /**
     * Example:
     * import React from 'react
     * useLogoElement: <img src={'images/logo-blauw.png'} width={50} height={50} />,
     */
    useLogoElement: (
      <img src={'/images/logo-blauw.png'} width={75} height={36} />
    ),
  },
  footer: {
    copyright: `© Copyright ${new Date().getFullYear()} Drover. All rights reserved.`,
    hideCopyright: false,
    hideLegal: false,
    hideMenu: false,
    hideSocial: false,
    // Thanks for using Wheelroom! We appreciate your support.
    supportWheelroom: true,
    useLogoElement: (
      <img src={'/images/logo-wit.png'} width={200} height={95} />
    ),
  },
  sitemap: {
    headingElementName: 'h4',
    hideMenu: false,
    hideSegmentHeading: false,
  },
}
