import {
  wrSystemConfig,
  SectionWheels,
  supportsDarkMode,
} from '@wheelroom/core'
import { droverBlue, droverOrange, droverGrey } from '../themes/drover/drover'

export const sectionWheels: SectionWheels = {
  defaultThemeId: supportsDarkMode() ? 'droverOrange' : 'droverBlue',
  themes: {
    droverBlue,
    droverOrange,
    droverGrey,
    glacierLight: droverBlue,
    glacierDark: droverBlue,
  },
  wrSystemConfig,
}
